import React, { useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { CartContext } from "../../context/CartContext"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Totals from "../../components/cart/Totals"
import { buttonOne, colors, headlineThree, medWrapper } from "../../Utilities"

const Cart = props => {
  const { cart } = useContext(CartContext)

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={``}
        description={``}
        metaImg={``}
        location={props.location.pathname}
      />
      <CartHeader>
        <h2>Your Cart</h2>
      </CartHeader>
      <Totals />
      {cart && cart.length > 0 && (
        <CheckoutButton>
          <div className="wrapper">
            <div className="btnContainer">
              <Link to="/shop/checkout">Proceed to checkout</Link>
            </div>
          </div>
        </CheckoutButton>
      )}
    </Layout>
  )
}

const CartHeader = styled.div`
  padding: 1.5rem 3rem;
  background-color: ${colors.colorTertiary};
  text-align: center;

  h2 {
    ${headlineThree};
    color: ${colors.white};
    text-transform: uppercase;
  }
`

const CheckoutButton = styled.div`
  .wrapper {
    ${medWrapper};
  }

  .btnContainer {
    width: 100%;
    text-align: right;

    @media (min-width: 768px) {
      width: calc(50%);
      margin-left: calc(50%);
      margin-top: 2.5rem;
    }
  }

  a {
    ${buttonOne};
  }
`

export default Cart
