import React, { useContext } from "react"
import { CartContext } from "../../context/CartContext"
import styled from "styled-components"
import {
  bodyCopy,
  headlineThree,
  medWrapper,
  fontSizer,
  buttonOne,
} from "../../Utilities"

import Table from "./Table"
import CartTotalTable from "./CartTotalTable"
import GiftCard from "./GiftCard"

const Totals = () => {
  const { cart, changeCartQty } = useContext(CartContext)
  return (
    <TotalsStyled>
      <div className="wrapper">
        <div className="cartSide">
          <h2>Thank you for shopping with Drift Out west</h2>
          <p>Check back often for new products!</p>
        </div>
        <div className="cartdetails">
          <Table cart={cart} changeCartQty={changeCartQty} />
          <GiftCard />
          <CartTotalTable cart={cart} />
        </div>
      </div>
    </TotalsStyled>
  )
}

const TotalsStyled = styled.div`
  .wrapper {
    ${medWrapper};
    align-items: flex-start;

    @media (max-width: 767px) {
      padding: 0 1.5rem;
      max-width: 50rem;
    }
  }

  .cartSide {
    width: 100%;
    margin-top: 2rem;
    padding: 1rem;
    border: solid 1px #91a2a2;
    text-align: center;

    @media (min-width: 768px) {
      width: calc(20% - 3rem);
      margin-top: 0;
      margin-right: 3rem;
    }

    h2 {
      ${headlineThree};
      ${fontSizer(1.8, 2.2, 76.8, 160, 2)}
      line-height: 1.25;
    }

    p {
      ${bodyCopy};
    }
  }

  .cartdetails {
    width: 100%;
    margin-top: 5rem;

    @media (min-width: 768px) {
      width: calc(80%);
      margin-top: 0;
    }

    &__checkout {
      margin: 5rem auto 2rem;
      width: 100%;
      text-align: right;

      button {
        ${buttonOne};
      }
    }
  }
`

export default Totals
