import React, { useContext } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import axios from "axios"
import { bodyCopy, colors, fontSizer } from "../../Utilities"
import { formatPrice } from "../../Utilities/cart/format"
import { UiContext } from "../../context/UiContext"
import { Link } from "gatsby"

const Table = ({ cart, changeCartQty }) => {
  const { state: uiState, dispatch: uiDispatch } = useContext(UiContext)
  const handleAddToCartTotals = async product => {
    uiDispatch({ type: "SERVER_LOAD" })
    const hasVariant = product.variants_picked.length === 0 ? false : true
    let confirmedMaxTotal
    let confirmedProductName

    if (hasVariant) {
      const res = await axios.get(
        `${process.env.GATSBY_API_URL}/variations/${product.var_selected_stock_id}`
      )
      confirmedMaxTotal = res.data.stock
      confirmedProductName = res.data.name
    } else {
      const res = await axios.get(
        `${process.env.GATSBY_API_URL}/products/${product.strapiId}`
      )
      confirmedMaxTotal = res.data.stock
      confirmedProductName = res.data.name
    }
    if (product.qty >= confirmedMaxTotal) {
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      return uiDispatch({
        type: "ALERT_ERROR",
        payload: `The total stock we have is ${confirmedMaxTotal}. There is currenly no more than ${confirmedMaxTotal} ${confirmedProductName} in stock.`,
      })
    } else {
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      changeCartQty(product, "add")
    }
  }
  return (
    <TableStyled>
      <div className="tableHeader">
        <div className="tableHeader__remove"></div>
        <div className="tableHeader__image">
          <p>Image</p>
        </div>
        <div className="tableHeader__name">
          <p>Product</p>
        </div>
        <div className="tableHeader__price">
          <p>Price</p>
        </div>
        <div className="tableHeader__qty">
          <p>Qty</p>
        </div>
        <div className="tableHeader__subtotal">
          <p>SubTotal</p>
        </div>
      </div>
      {cart.length > 0 ? (
        <div className="tableBody">
          {cart.map(item => {
            return (
              <div key={item.cart_id} className="tableBody__item">
                <div className="tableBody__item--remove">
                  <button
                    onClick={() => changeCartQty(item, "delete")}
                    type="button"
                  >
                    <span>&times;</span>
                    <span className="btnText">Remove</span>
                  </button>
                </div>
                <div className="tableBody__item--image">
                  <Link to={`/products/${item.slug}`}>
                    {item.selected_varient_details &&
                    item.selected_varient_details.image &&
                    item.selected_varient_details.image.childImageSharp
                      .fluid ? (
                      <>
                        <Img
                          fluid={
                            item.selected_varient_details.image.childImageSharp
                              .fluid
                          }
                        />
                      </>
                    ) : (
                      <>
                        <Img
                          fluid={item.featured_image.childImageSharp.fluid}
                        />
                      </>
                    )}
                  </Link>
                </div>
                <div className="tableBody__item--name">
                  <p>{item.name}</p>
                  {item.variants_picked.length > 0 &&
                    item.variants_picked.map((pick, index) => {
                      return (
                        <p key={index}>
                          {Object.keys(pick)[0]} : {Object.values(pick)[0]}
                        </p>
                      )
                    })}
                </div>
                <div className="tableBody__item--price">
                  <p>{formatPrice(item.price_in_cents)}</p>
                </div>
                <div className="tableBody__item--qty">
                  <p>{item.qty}</p>
                  <span className="quantity-nav">
                    <button
                      className="quantity-nav__add"
                      onClick={() => handleAddToCartTotals(item)}
                      type="button"
                    >
                      &#43;
                    </button>
                    <button
                      disabled={item.qty <= 1}
                      className="quantity-nav__subtract"
                      onClick={() => changeCartQty(item, "subtract")}
                      type="button"
                    >
                      &#8722;
                    </button>
                  </span>
                </div>
                <div className="tableBody__item--subTotal">
                  <p>{formatPrice(item.price_in_cents * item.qty)}</p>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div>
          <p>No Products in your cart</p>
        </div>
      )}
    </TableStyled>
  )
}

const TableStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2.5rem;
  border: solid 0.1rem ${colors.black};

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem;

    p {
      ${bodyCopy};
      margin: 0;
      ${fontSizer(1.2, 1.4, 76.8, 150, 1)};
      text-align: center;
      text-transform: uppercase;
    }

    &__image {
      display: none;
      text-align: center;

      @media (min-width: 768px) {
        display: block;
        padding: 0.5rem;
        width: calc(10%);
      }
    }

    &__remove,
    &__price,
    &__qty,
    &__subtotal {
      width: calc(15%);
      text-align: center;

      @media (min-width: 768px) {
        width: calc(10%);
        padding: 0.5rem;
      }
    }

    &__remove {
      height: 2rem;
    }

    &__name {
      width: calc(40%);
      text-align: center;

      @media (min-width: 768px) {
        width: calc(50%);
        padding: 0.5rem;
      }
    }
  }

  .tableBody {
    width: 100%;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.5rem;
      border-top: solid 0.1rem ${colors.black};

      & > div {
        padding: 0.5rem;
      }

      p {
        ${bodyCopy};
        margin: 0;
        ${fontSizer(1.2, 1.4, 76.8, 150, 1)};
        text-align: center;
        text-transform: uppercase;
      }

      &--remove {
        width: calc(10%);
        text-align: center;

        button {
          transition: all 0.3s ease-out;
          border: none;
          background-color: ${colors.white};
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;

          span {
            display: block;
          }

          span.btnText {
            ${fontSizer(1.2, 1.2, 76.8, 150, 1)};
          }

          &:focus {
            outline: none;
            color: ${colors.colorTertiary};
          }

          &:hover {
            color: ${colors.colorTertiary};
          }
        }
      }

      &--image {
        display: none;
        width: calc(0%);
        text-align: center;

        @media (min-width: 768px) {
          display: block;
          width: calc(10%);
        }
      }

      &--remove,
      &--price,
      &--qty,
      &--subtotal {
        width: calc(15%);
        text-align: center;
        @media (min-width: 768px) {
          width: calc(10%);
        }
      }

      &--name {
        width: calc(40% - 2rem);
        @media (min-width: 768px) {
          width: calc(50% - 2rem);
        }
        margin: 0 1rem;
        p {
          text-align: left;
        }
      }

      &--price {
        width: calc(15%);

        @media (min-width: 768px) {
          width: calc(10%);
        }
      }

      &--qty {
        width: calc(15%);

        @media (min-width: 768px) {
          width: calc(10%);
        }

        .quantity-nav {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          flex-direction: row;

          @media (min-width: 768px) {
            flex-wrap: nowrap;
          }

          button {
            width: 5rem;
            height: 5rem;
            transition: all 0.3s ease-out;
            border: 0.1rem solid #eee;
            background-color: ${colors.white};
            color: ${colors.sliver};
            cursor: pointer;

            @media (min-width: 768px) {
              width: 3rem;
              height: 3rem;
            }

            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }

          button.quantity-nav__add {
            color: ${colors.colorPrimary};

            &:hover {
              background-color: ${colors.colorPrimary};
              color: ${colors.white};
            }
          }

          button.quantity-nav__subtract {
            color: ${colors.colorPrimary};

            &:hover {
              background-color: ${colors.colorPrimary};
              color: ${colors.white};
            }
          }
        }
      }

      &--subTotal {
        width: calc(15%);

        @media (min-width: 768px) {
          width: calc(10%);
        }
      }
    }
  }
`

export default Table
